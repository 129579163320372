/* src/styles/IMTSDashboard.css */
.imts-dashboard-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    text-align: center;
  }
  
  .review-section {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .review-box {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 45%;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .review-box:hover {
    transform: scale(1.05);
  }
  
  .review-box h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .review-box p {
    color: #777;
  }
  