/* src/styles/WhatToDo.css */

.what-to-do {
    text-align: center;
    padding: 50px;
    background-color: #f5f5f5;
  }
  
  .what-to-do h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .intro-text {
    width: 70%;
    font-size: 1.3em;
    color: #555;
    margin: auto;
    margin-bottom: 40px;
  }
  
  .todo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  
  .todo-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .todo-card:hover {
    transform: translateY(-10px);
  }
  
  .todo-img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 15px;
  }
  
  .todo-card p {
    font-size: 1.1em;
    color: #333;
  }
  
  .extra {
    margin-top: 30px;
    font-size: 2rem;
    font-weight: bolder;
  }
  
  @keyframes moveLeftRight {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(20px);
    }
  }
  
  .buyyyy {
    font-size: 1.7rem;
    font-weight: 700;
    padding: 15px;
    background-color: green;
    color: white;
    width: 60%;
    margin: auto;
    margin-top: 60px;
    border-radius: 25px;
    border: none;
    animation: moveLeftRight 2s infinite;
  }
  
  .buyyyy:hover {
    background-color: rgba(2, 192, 2, 0.888);
  }
  
  /* Media Queries for Mobile Responsiveness */
  @media (max-width: 768px) {
    .what-to-do h1 {
      font-size: 2rem;
    }
  
    .intro-text {
      width: 90%;
      font-size: 1.1em;
    }
  
    .todo-card {
      width: 200px;
      padding: 15px;
    }
  
    .todo-img {
      width: 80px;
      height: 80px;
    }
  
    .todo-card p {
      font-size: 1em;
    }
  
    .extra {
      font-size: 1.5rem;
    }
  
    .buyyyy {
      font-size: 1.5rem;
      width: 80%;
      padding: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .what-to-do h1 {
      font-size: 1.5rem;
    }
  
    .intro-text {
      width: 95%;
      font-size: 1rem;
    }
  
    .todo-card {
      width: 100%;
      padding: 10px;
    }
  
    .todo-img {
      width: 60px;
      height: 60px;
    }
  
    .todo-card p {
      font-size: 0.9em;
    }
  
    .extra {
      font-size: 1.3rem;
    }
  
    .buyyyy {
      font-size: 1.3rem;
      width: 90%;
      padding: 10px;
    }
  }
  