/* src/styles/ReviewForm.css */
.review-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.review-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 1.1rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.form-group button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
}

.form-group button:hover {
  background-color: #0056b3;
}

/* Star Rating Styles */
.rating-container {
  display: flex;
  justify-content: center;
}

.star {
  font-size: 24px;
  color: #ccc;
  cursor: pointer;
  transition: color 0.2s;
}

.star.filled {
  color: #f39c12;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .review-form-container {
    padding: 15px;
  }

  .review-form-container h2 {
    font-size: 1.8rem;
  }

  .form-group label {
    font-size: 1rem;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.95rem;
  }

  .form-group button {
    font-size: 1rem;
  }

  .star {
    font-size: 22px;
  }
}

@media (max-width: 480px) {
  .review-form-container {
    padding: 10px;
  }

  .review-form-container h2 {
    font-size: 1.6rem;
  }

  .form-group label {
    font-size: 0.9rem;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.9rem;
  }

  .form-group button {
    font-size: 0.95rem;
    padding: 8px;
  }

  .star {
    font-size: 20px;
  }
}
