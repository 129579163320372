/* src/styles/Signup.css */
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  padding: 20px; /* Added padding for better spacing on smaller screens */
}

.signup-form {
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%; /* Adjusted to fit the screen width */
  max-width: 400px;
  box-sizing: border-box; /* Ensure padding and borders are included in the width */
}

.signup-form h2 {
  margin-bottom: 20px;
  text-align: center;
}

.signup-form div {
  margin-bottom: 15px;
}

.signup-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.signup-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.signup-form button {
  width: 100%;
  padding: 12px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.signup-form button:hover {
  background: #0056b3;
}

.signup-form p {
  margin-top: 10px;
  text-align: center;
}

.signup-form span {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .signup-form {
    padding: 30px;
  }

  .signup-form input {
    font-size: 14px;
  }

  .signup-form button {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .signup-form {
    padding: 20px;
  }

  .signup-form h2 {
    font-size: 18px;
  }

  .signup-form input {
    padding: 8px;
    font-size: 14px;
  }

  .signup-form button {
    padding: 10px;
    font-size: 14px;
  }
}
