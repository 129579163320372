/* src/styles/IMTSAdminLogin.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .login-form {
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .login-form div {
    margin-bottom: 15px;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-form button {
    width: 100%;
    padding: 12px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-form button:hover {
    background: #0056b3;
  }
  
  .login-form p {
    margin-top: 10px;
    text-align: center;
  }
  
  .login-form p:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .login-form {
      padding: 30px;
      width: 100%;
      max-width: 350px;
    }
  
    .login-form h2 {
      font-size: 1.5rem;
    }
  
    .login-form button {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .login-form {
      padding: 20px;
      width: 100%;
      max-width: 300px;
    }
  
    .login-form h2 {
      font-size: 1.3rem;
    }
  
    .login-form button {
      font-size: 14px;
      padding: 10px;
    }
  
    .login-form input {
      padding: 8px;
    }
  
    .login-form label {
      font-size: 0.9rem;
    }
  }
  