.bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #333;
    color: white;
    z-index: 1000;
}

.price {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.fake {
    text-decoration: line-through;
    color: red;
}

.amount {
    font-size: 1.5rem;
    font-weight: bold;
}

.timer {
    font-size: 1rem;
    margin-top: 5px;
}

.buyyyy {
    font-size: 1.7rem;
    font-weight: 700;
    padding: 15px;
    background-color: green;
    color: white;
    border-radius: 25px;
    border: none;
    animation: moveLeftRight 2s infinite;
}

.buyyyy:hover {
    background-color: rgba(2, 192, 2, 0.888);
}

@keyframes moveLeftRight {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(20px);
    }
}

/* Media queries for mobile devices */
@media (max-width: 768px) {
    .bar {
        flex-direction: column;
        align-items: center;
        padding: 15px;
        text-align: center;
    }

    .price {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .amount {
        font-size: 1.2rem;
    }

    .timer {
        font-size: 0.9rem;
    }

    .buyyyy {
        font-size: 1.4rem;
        padding: 10px 20px;
        width: 100%; /* Make the button take the full width on mobile */
    }
}

@media (max-width: 480px) {
    .amount {
        font-size: 1rem;
    }

    .timer {
        font-size: 0.8rem;
    }

    .buyyyy {
        font-size: 1.2rem;
        padding: 8px 15px;
    }
}
