/* src/styles/IMTSBelow3.css */
.reviews-container {
  padding: 20px;
  max-width: 900px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.sort-container {
  text-align: right;
  margin-bottom: 10px;
}

.sort-container label {
  margin-right: 10px;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.sort-container select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #ffffff;
  cursor: pointer;
}

.reviews-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.reviews-table th, .reviews-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.reviews-table th {
  background-color: #f44336;
  color: white;
}

.reviews-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.reviews-table tr:hover {
  background-color: #ddd;
}

.reviews-table button {
  padding: 8px 12px;
  color: white;
  background-color: #d9534f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reviews-table button:hover {
  background-color: #c9302c;
}
