/* MagicCodePerfect.css */

.magic-perfect {
    text-align: center;
    padding: 30px; /* Reduced padding for smaller screens */
    background-color: #f9f9f9;
}

.magic-perfect h1 {
    font-size: 2rem; /* Reduced font size for smaller screens */
    color: #333;
    margin-bottom: 20px;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 180px; /* Slightly smaller width for better mobile fit */
    text-align: center;
    transition: transform 0.3s ease;
}

.card:hover {
    transform: translateY(-10px);
}

.card-img {
    width: 80px; /* Smaller image size for mobile screens */
    height: 80px;
    object-fit: contain;
    margin-bottom: 15px;
}

.card p {
    font-size: 0.9em; /* Adjusted text size for mobile */
    color: #555;
}

@keyframes moveLeftRight {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(20px);
    }
}

.buyyyy {
    font-size: 1.5rem; /* Adjusted font size for better fit on mobile */
    font-weight: 700;
    padding: 12px; /* Reduced padding for better mobile experience */
    background-color: green;
    color: white;
    width: 80%; /* Increased width to fit more on smaller screens */
    margin: auto;
    margin-top: 40px;
    border-radius: 25px;
    border: none;
    animation: moveLeftRight 2s infinite;
}

.buyyyy:hover {
    background-color: rgba(2, 192, 2, 0.888);
}

@media (max-width: 768px) {
    .magic-perfect h1 {
        font-size: 1.8rem;
    }

    .card {
        width: 160px; /* Smaller width for tablet-sized screens */
    }

    .buyyyy {
        font-size: 1.4rem;
        width: 70%; /* Adjust width for smaller tablet screens */
    }
}

@media (max-width: 480px) {
    .magic-perfect {
        padding: 20px;
    }

    .magic-perfect h1 {
        font-size: 1.5rem;
    }

    .card {
        width: 140px; /* Smaller width for mobile screens */
        padding: 15px;
    }

    .card-img {
        width: 60px; /* Smaller image size for mobile */
        height: 60px;
    }

    .buyyyy {
        font-size: 1.2rem;
        width: 90%; /* Increase width for narrow screens */
    }
}
