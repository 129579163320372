/* src/styles/IMTSAbove3.css */
.reviews-container {
  padding: 20px;
  max-width: 900px;
  margin: auto;
  background-color: #e0f7fa;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #006064;
  margin-bottom: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.sort-container {
  text-align: right;
  margin-bottom: 10px;
}

.sort-container label {
  margin-right: 10px;
  font-family: 'Arial', sans-serif;
  color: #006064;
}

.sort-container select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #b2ebf2;
  background-color: #ffffff;
  cursor: pointer;
}

.reviews-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.reviews-table th, .reviews-table td {
  padding: 14px;
  border: 1px solid #b2ebf2;
  text-align: left;
  font-family: 'Arial', sans-serif;
}

.reviews-table th {
  background-color: #00796b;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.reviews-table tr:nth-child(even) {
  background-color: #b2ebf2;
}

.reviews-table tr:hover {
  background-color: #80deea;
}

.reviews-table button {
  padding: 8px 16px;
  color: white;
  background-color: #00796b;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.reviews-table button:hover {
  background-color: #004d40;
  transform: scale(1.05);
}
