/* src/styles/WelcomeBox.css */

/* Base styles for all screens */
.welcome-box {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .welcome-box h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .rate-us-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .rate-us-button:hover {
    background-color: #0056b3;
  }
  
  /* Media query for tablets and larger screens */
  @media (min-width: 768px) {
    .welcome-box {
      padding: 30px;
    }
  
    .welcome-box h1 {
      font-size: 28px;
    }
  
    .rate-us-button {
      font-size: 18px;
      padding: 12px 24px;
    }
  }
  
  /* Media query for mobile devices */
  @media (max-width: 767px) {
    .welcome-box {
      padding: 15px;
    }
  
    .welcome-box h1 {
      font-size: 20px;
    }
  
    .rate-us-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  
  /* Media query for very small mobile devices */
  @media (max-width: 480px) {
    .welcome-box {
      padding: 10px;
    }
  
    .welcome-box h1 {
      font-size: 18px;
    }
  
    .rate-us-button {
      font-size: 12px;
      padding: 6px 12px;
    }
  }
  