/* src/styles/WhatMagicCodes.css */
.qrcodes {
    margin-top: 50px;
    padding: 0 20px; /* Add padding for mobile screens */
  }
  
  h1 {
    font-size: 2.5rem;
    color: #333;
    margin: 30px auto 20px;
    border-bottom: 4px solid salmon;
    width: fit-content;
    text-align: center;
  }
  
  .answer {
    width: 70%;
    margin: 35px auto;
    text-align: center;
    font-size: 1.2rem;
  }
  
  .tick {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
  }
  
  .ticks {
    border: 2px solid red;
    width: 55%;
    margin: 10px auto;
    padding: 15px;
    border-radius: 15px;
    font-size: 1rem;
    text-align: center;
  }
  
  .ticks:hover {
    background-color: blanchedalmond;
  }
  
  @keyframes moveLeftRight {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(20px);
    }
  }
  
  .buyyy {
    font-size: 1.7rem;
    font-weight: 700;
    padding: 15px;
    background-color: green;
    color: white;
    width: 60%;
    margin: 60px auto;
    border-radius: 25px;
    border: none;
    animation: moveLeftRight 2s infinite;
    display: block;
    text-align: center;
  }
  
  .buyyy:hover {
    background-color: rgba(2, 192, 2, 0.888);
  }
  
  .ok {
    margin-top: 30px;
    font-size: 2rem;
    font-weight: bolder;
    text-align: center;
  }
  
  /* Media Queries for Mobile Responsiveness */
  @media (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }
  
    .answer {
      width: 90%;
      font-size: 1rem;
    }
  
    .ticks {
      width: 80%;
      font-size: 0.9rem;
    }
  
    .buyyy {
      font-size: 1.5rem;
      width: 80%;
    }
  
    .ok {
      font-size: 1.7rem;
    }
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 1.8rem;
    }
  
    .answer {
      font-size: 0.9rem;
    }
  
    .ticks {
      width: 90%;
      padding: 12px;
      font-size: 0.85rem;
    }
  
    .buyyy {
      font-size: 1.3rem;
      padding: 12px;
      width: 90%;
    }
  
    .ok {
      font-size: 1.5rem;
    }
  }
  