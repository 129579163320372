.magic-codes-work {
    max-width: 80%;
    margin: auto;
    margin-top: 40px;
    padding: 20px;
    text-align: center;
}

.magic-codes-work h1 {
    font-size: 3.5rem; /* Increased font size */
    color: #333;
    margin-bottom: 50px;
}

.timeline {
    position: relative;
    max-width: 100%;
    margin: auto;
    padding: 20px 0;
    list-style-type: none;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 10px; /* Line thickness */
    background-color: #ddd;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -5px; /* Centered adjustment */
}

.timeline li {
    position: relative;
    margin: 40px 0; /* Spacing between items */
    clear: both;
    transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.timeline li::before {
    content: '✔️';
    position: absolute;
    width: 50px; /* Tick size */
    height: 50px;
    line-height: 50px;
    background-color: white;
    border: 5px solid green; /* Border width */
    border-radius: 50%;
    top: 0;
    left: 50%;
    margin-left: -25px; /* Centered adjustment */
    text-align: center;
    font-size: 2.5rem; /* Tick font size */
    color: green;
    z-index: 1;
}

.timeline li div {
    padding: 25px; /* Padding around content */
    background-color: #f9f9f9;
    border-radius: 10px; /* Border radius */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Shadow */
    position: relative;
    width: 55%; /* Width of content */
    font-size: 1.4rem; /* Font size */
    z-index: 2;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

.timeline li:hover div {
    background-color: #e0f7fa; /* Change background color on hover */
    transform: scale(1.02); /* Slightly scale up on hover */
}

.timeline li:nth-child(odd) div {
    left: calc(50% + 80px); /* Increased gap on right side */
    transform: translateX(0);
}

.timeline li:nth-child(even) div {
    left: calc(50% - 65%); /* Decreased gap on left side */
    transform: translateX(0);
}

@keyframes moveLeftRight {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(20px); /* Adjust the distance as needed */
    }
}

.buyyyy {
    font-size: 1.7rem;
    font-weight: 700; /* Font weight should be a number, not in rem */
    padding: 15px;
    background-color: green;
    color: white;
    width: 60%;
    margin: auto;
    margin-top: 60px;
    border-radius: 25px;
    border: none;
    animation: moveLeftRight 2s infinite; /* Add the animation */
}

.buyyyy:hover {
    background-color: rgba(2, 192, 2, 0.888);
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .magic-codes-work h1 {
        font-size: 2.5rem; /* Reduce font size */
    }

    .timeline li div {
        width: 70%; /* Increase width on mobile */
        font-size: 1.2rem; /* Adjust font size */
        padding: 20px; /* Reduce padding */
    }

    .timeline li:nth-child(odd) div,
    .timeline li:nth-child(even) div {
        left: 50%;
        transform: translateX(-50%); /* Center items */
    }

    .timeline::after {
        left: 50%;
        margin-left: -5px;
    }

    .buyyyy {
        font-size: 1.5rem; /* Adjust button font size */
        width: 80%; /* Increase button width */
        padding: 12px; /* Adjust padding */
    }
}

@media (max-width: 480px) {
    .magic-codes-work h1 {
        font-size: 2rem; /* Further reduce font size */
    }

    .timeline li div {
        width: 90%; /* Further increase width */
        font-size: 1rem; /* Adjust font size */
        padding: 15px; /* Further reduce padding */
    }

    .buyyyy {
        font-size: 1.3rem; /* Adjust button font size */
        width: 90%; /* Increase button width */
        padding: 10px; /* Adjust padding */
    }
}
