/* src/styles/BelowThreeStarReviewsPage.css */
.reviews-container {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.reviews-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.reviews-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.reviews-table th,
.reviews-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.reviews-table th {
  background-color: #f8f8f8;
}

.reviews-table tbody tr:hover {
  background-color: #f1f1f1;
}

.reviews-table button {
  padding: 8px 12px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reviews-table button:hover {
  background-color: #ff3333;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .reviews-container {
    padding: 15px;
    max-width: 100%;
  }

  .reviews-table th,
  .reviews-table td {
    padding: 10px;
    font-size: 14px;
  }

  .reviews-table {
    margin-bottom: 15px;
  }

  .reviews-table button {
    padding: 6px 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .reviews-table th,
  .reviews-table td {
    padding: 8px;
    font-size: 12px;
  }

  .reviews-container h2 {
    font-size: 1.5rem;
  }

  .reviews-table button {
    padding: 5px 8px;
    font-size: 12px;
  }
}
