/* src/styles/Header.css */
.header {
    max-width: 90%;
    margin: auto;
    margin-top: 40px;
    text-align: center;
}

.header h1 {
    padding: 17px;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.header h2 {
    margin-top: 20px;
    font-size: 1.8rem; /* Adjusted font size for better readability */
}

.tube {
    font-size: 1.3rem;
    color: red;
    margin-top: 20px;
}

.arrow {
    font-size: 2rem;
    color: black;
    animation: bounce 1s infinite;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.linkk {
    margin: auto;
    margin-top: 20px;
    border-radius: 2px;
    text-align: center;
}

.linkk iframe {
    width: 100%;
    max-width: 853px;
    height: auto;
}

.deal {
    max-width: fit-content;
    padding: 12px;
    border-radius: 7px;
    font-weight: 600;
    margin: auto;
    margin-top: 15px;
    background-color: rgb(255, 149, 0);
    color: white;
}

.buyy {
    font-size: 1.7rem;
    font-weight: 700;
    padding: 15px;
    background-color: green;
    color: white;
    width: 60%;
    margin: 60px auto;
    border-radius: 25px;
    border: none;
    animation: moveLeftRight 2s infinite;
    display: block;
    text-align: center;
}

.buyy:hover {
    background-color: rgba(2, 192, 2, 0.888);
}

p {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 8px;
}

@media (max-width: 768px) {
    .header h1 {
        font-size: 2rem;
    }

    .header h2 {
        font-size: 1.5rem;
    }

    .tube {
        font-size: 1.2rem;
    }

    .arrow {
        font-size: 1.5rem;
    }

    .buyy {
        font-size: 1.5rem;
        width: 80%;
    }
}

@media (max-width: 480px) {
    .header h1 {
        font-size: 1.5rem;
        padding: 10px;
    }

    .header h2 {
        font-size: 1.2rem;
    }

    .tube {
        font-size: 1rem;
    }

    .arrow {
        font-size: 1.2rem;
    }

    .buyy {
        font-size: 1.2rem;
        width: 90%;
        padding: 12px;
        margin: 40px auto;
    }

    .deal {
        font-size: 1rem;
        padding: 8px;
    }

    p {
        font-size: 1rem;
    }
}
