/* src/styles/QRCodePage.css */
.qr-code-container {
  text-align: center;
  padding: 20px;
}

.qr-code-container h2 {
  margin-bottom: 20px;
  font-size: 2rem; /* Adjusted font size */
}

.qr-code-container p {
  margin-top: 20px;
  font-size: 1.2rem; /* Adjusted font size */
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .qr-code-container {
      padding: 15px; /* Reduced padding */
  }

  .qr-code-container h2 {
      font-size: 1.8rem; /* Further reduced font size */
  }

  .qr-code-container p {
      font-size: 1rem; /* Adjusted font size for paragraph */
  }

  .qr-code-container .qr-code {
      width: 80%;
      max-width: 256px; /* Ensure it doesn't get too big */
      height: auto;
  }
}

@media (max-width: 480px) {
  .qr-code-container {
      padding: 10px; /* Further reduced padding */
  }

  .qr-code-container h2 {
      font-size: 1.5rem; /* Further reduced font size */
  }

  .qr-code-container p {
      font-size: 0.9rem; /* Further reduced font size */
  }

  .qr-code-container .qr-code {
      width: 100%;
      max-width: 200px; /* Smaller max size */
      height: auto;
  }
}
