/* src/styles/Dashboard.css */
.dashboard-container {
  padding: 20px;
  text-align: center;
}

.dashboard-container h2 {
  margin-bottom: 20px;
}

.dashboard-boxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.dashboard-box {
  background: #007bff;
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s, background 0.2s;
}

.dashboard-box h3 {
  margin: 0;
  font-size: 18px;
}

.dashboard-box:hover {
  transform: translateY(-5px);
  background: #0056b3;
}

/* Medium screens (tablets, small laptops) */
@media (max-width: 768px) {
  .dashboard-box {
    width: 150px;
    padding: 15px;
  }

  .dashboard-box h3 {
    font-size: 16px;
  }
}

/* Small screens (phones) */
@media (max-width: 480px) {
  .dashboard-container {
    padding: 10px;
  }

  .dashboard-boxes {
    flex-direction: column;
    align-items: center;
  }

  .dashboard-box {
    width: 100%;
    padding: 15px;
    margin: 5px 0;
  }

  .dashboard-box h3 {
    font-size: 16px;
  }
}
